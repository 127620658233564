<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'10'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>矿山管理</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('0')">
					<span>矿山列表</span>
				</div>
        <!--
				<div class="left-list-item left-list-active" @click="leftClick('1')">
					<span>备件分类</span>
				</div>
				<div class="left-list-item" @click="leftClick('2')">
					<span>备件数据</span>
				</div>-->
			</div>
			<div class="right-next">
				<div class="title">
					<span>{{title}}</span>

				</div>
        <div> <el-button type="danger" @click="back()">返回</el-button></div>
				<div class="edit-form">
					<div class="item">
						<div class="label">文件夹名称：</div>
						<el-input placeholder="文件夹名称" v-model="editForm.name"></el-input>
					</div>
					<div class="item submit">
						<el-button type="primary" @click="submit">保存添加</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import leftList from "../../../components/leftList.vue";
	import topHeader from "../../../components/header.vue"
	export default {
		components: {
			leftList,
			topHeader
		},
		inject: ["reload"],
		data() {
			return {
				title: '添加备件分类',
				isCollapse: false,
				options: [],
				editForm: {
					name: '',
				},

			};
		},
		created() {
			if (this.$route.query.id) {
				this.title = '编辑备件分类';
				this.getinfo();
			}
		},
		methods: {

			submit() {
				if (this.editForm.name == '') {
					this.$notify.error({
						title: '提示',
						message: '请填写文件夹名称'
					});
					return false;
				}
				if (this.$route.query.id) {
					this.$post('/upd/mine_package', {
							id: this.$route.query.id,
							name: this.editForm.name,
						}).then((res) => {
              console.log(res.data);
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
               this.$router.go(-1);
							} else {
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
							// console.log(res);
						})
						.catch((res) => {
							console.log(res);
						});
				} else {
					this.$post('add/mine_file', {
							name: this.editForm.name,
              mine_id:this.$route.query.mine_id,
              pid:this.$route.query.pid,
              type_id:1,
						}).then((res) => {
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
                this.$router.go(-1);
							} else {
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
							// console.log(res);
						})
						.catch((res) => {
							console.log(res);
						});
				}
			},
      back(){
        this.$router.go(-1);
      },
			getinfo() {
				this.$get('mine_file/get_details', {
					id: this.$route.query.id
				}).then(res => {
					this.editForm.name = res.data.data.name;
				})
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/mine") {
					this.$router.push("/mine");
				} else if(index == 1 && this.$route.path != "/minetype"){
					this.$router.push("/minetype");
				} else if(index == 2 && this.$route.path != "/minefile"){
					this.$router.push("/minefile");
				} else {
					this.reload();
				}
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
		}
	};
</script>

<style lang="scss" scoped>
	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.date-search {
			margin-top: 30px;

			.el-button {
				padding: 12px 55px;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 10px;
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}

	.edit-form {
		.submit {
			width: 25%;
			text-align: center;

			.el-button {
				width: 150px;
			}
		}

		.item {
			margin-top: 30px;

			.label {
				display: inline-block;
				vertical-align: middle;
				width: 120px;
			}

			.yield {
				margin-left: 30px;
				font-size: 14px;

				.commodity {
					margin-top: 15px;

					.el-button {
						margin-left: 15px;
					}
				}
			}

			.el-input,
			.el-select {
				width: 230px;
			}
		}
	}
</style>
